<template>
	<div class="Security">
		<div class="title-hang">
			<div class="fl">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl"></div>
			<div class="zhuangshizj fl">{{projectData.project}}_安全设置</div>
			<div class="zhuangshiyb fl"></div>
			<div class="fr">
				<div class="fl">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<div class="security-tab">
			<div class="security-tab-tit">安全生产设置参数</div>
			<div class="security-tab-box flex">
				<div class="tab-row">
					<div>煤气总管阀上下限</div>
					<div>1#煤气调节阀上下限</div>
					<div>1#空气调节阀上下限</div>
					<div>1#煤气调节阀上下限(闭炉时）</div>
					<div class="lastchild">1#空气调节阀上下限(闭炉时）</div>
				</div>
				<div class="tab-row1">
					<div class="widthmin" @click="
              toIpt(
                infoList.V_GONGGONG__p__MAN1.OutT,
                '煤气总管阀上限',
                'OutT',
                'V_GONGGONG__p__MAN1'
              )
            ">
						{{ infoList.V_GONGGONG__p__MAN1.OutT }}
					</div>
					<div @click="
              toIpt(
                infoList.V_RS__p__RSFB1__p__MAN1.OUTT,
                '1#煤气调节阀上限',
                'OUTT',
                'V_RS__p__RSFB1__p__MAN1'
              )
            ">
						{{ infoList.V_RS__p__RSFB1__p__MAN1.OUTT }}
					</div>
					<div @click="
              toIpt(
                infoList.V_RS__p__RSFB1__p__MAN2.OUTT,
                '1#空气调节阀上限',
                'OUTT',
                'V_RS__p__RSFB1__p__MAN2'
              )
            ">
						{{ infoList.V_RS__p__RSFB1__p__MAN2.OUTT }}
					</div>
					<div></div>
					<div class="last-child"></div>
				</div>
				<div class="tab-row1">
					<div class="widthmin" @click="
              toIpt(
                infoList.V_GONGGONG__p__MAN1.OutB,
                '煤气总管阀下限',
                'OutB',
                'V_GONGGONG__p__MAN1'
              )
            ">
						{{ infoList.V_GONGGONG__p__MAN1.OutB }}
					</div>
					<div class="widthmin" @click="
              toIpt(
                infoList.V_RS1.SLMQL_B1,
                '1#煤气调节阀下限',
                'OUTB',
                'V_RS1'
              )
            ">
						{{ infoList.V_RS1.SLMQL_B1 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS1.SLKQL_B1,
                  '1#空气调节阀下限',
                  'OUTB',
                  'V_GONGGONG__p__MAN1'
                )
              ">
						{{ infoList.V_RS1.SLKQL_B1 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS1.BLMQL_B1,
                  '1#煤气调节阀下限(闭炉时)',
                  'BLMQL_B1',
                  'V_RS1'
                )
              ">
						{{ infoList.V_RS1.BLMQL_B1 }}
					</div>
					<div class="last-child" @click="
                toIpt(
                  infoList.V_RS1.BLKQL_B1,
                  '1#空气调节阀下限(闭炉时)',
                  'BLKQL_B1',
                  'V_RS1'
                )
              ">
						{{ infoList.V_RS1.BLKQL_B1 }}
					</div>
				</div>
				<div class="tab-row">
					<div>1#风机调节上下限</div>
					<div>2#煤气调节阀上下限</div>
					<div>2#空气调节阀上下限</div>
					<div>2#煤气调节阀上下限(闭炉时）</div>
					<div class="lastchild">2#空气调节阀上下限(闭炉时）</div>
				</div>
				<div class="tab-row1">
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_GONGGONG__p__MAN2.OutT,
                  '1#风机调节上限',
                  'OutT',
                  'V_GONGGONG__p__MAN2'
                )
              ">
						{{ infoList.V_GONGGONG__p__MAN2.OutT }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB2__p__MAN1.OUTT,
                  '2#煤气调节阀上限',
                  'OUTT',
                  'V_RS__p__RSFB2__p__MAN1'
                )
              ">
						{{ infoList.V_RS__p__RSFB2__p__MAN1.OUTT }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB2__p__MAN2.OUTT,
                  '2#空气调节阀上限',
                  'OUTT',
                  'V_RS__p__RSFB2__p__MAN2'
                )
              ">
						{{ infoList.V_RS__p__RSFB2__p__MAN2.OUTT }}
					</div>
					<div></div>
					<div class="last-child"></div>
				</div>
				<div class="tab-row1">
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_GONGGONG__p__MAN2.OutB,
                  '1#风机调节下限',
                  'OutB',
                  'V_GONGGONG__p__MAN2'
                )
              ">
						{{ infoList.V_GONGGONG__p__MAN2.OutB }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB2__p__XK01,
                  '2#煤气调节阀下限',
                  'SLMQL_B2',
                  'V_RS__p__RSFB2__p__XK01'
                )
              ">
						{{ infoList.V_RS__p__RSFB2__p__XK01.SLMQL_B2}}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB2__p__XK02.SLKQL_B2,
                  '2#空气调节阀下限',
                  'SLKQL_B2',
                  'V_RS__p__RSFB2__p__XK02'
                )
              ">
						{{ infoList.V_RS__p__RSFB2__p__XK02.SLKQL_B2 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS2.BLMQL_B2,
                  '2#煤气调节阀下限(闭炉时)',
                  'BLMQL_B2',
                  'V_RS2'
                )
              ">
						{{ infoList.V_RS2.BLMQL_B2 }}
					</div>
					<div class="last-child" @click="
                toIpt(
                  infoList.V_RS2.BLKQL_B2,
                  '2#空气调节阀下限(闭炉时)',
                  'BLKQL_B2',
                  'V_RS2'
                )
              ">
						{{ infoList.V_RS2.BLKQL_B2 }}
					</div>
				</div>
				<div class="tab-row">
					<div>2#风机调节上下限</div>
					<div>3#煤气调节阀上下限</div>
					<div>3#空气调节阀上下限</div>
					<div>3#煤气调节阀上下限(闭炉时）</div>
					<div class="lastchild">3#空气调节阀上下限(闭炉时）</div>
				</div>
				<div class="tab-row1">
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_GONGGONG__p__MAN3.OutT,
                  '2#风机调节上限',
                  'OutT',
                  'V_GONGGONG__p__MAN3'
                )
              ">
						{{ infoList.V_GONGGONG__p__MAN3.OutT}}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB3__p__MAN1.OUTT,
                  '3#煤气调节阀上限',
                  'OUTT',
                  'V_RS__p__RSFB3__p__MAN1'
                )
              ">
						{{ infoList.V_RS__p__RSFB3__p__MAN1.OUTT }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB3__p__MAN2.OUTT,
                  '3#空气调节阀上限',
                  'OUTT',
                  'V_RS__p__RSFB3__p__MAN2'
                )
              ">
						{{ infoList.V_RS__p__RSFB3__p__MAN2.OUTT }}
					</div>
					<div></div>
					<div class="last-child"></div>
				</div>
				<div class="tab-row1">
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_GONGGONG__p__MAN3.OutB,
                  '2#风机调节下限',
                  'OutB',
                  'V_GONGGONG__p__MAN3'
                )
              ">
						{{ infoList.V_GONGGONG__p__MAN3.OutB }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS3.SLMQL_B3,
                  '3#煤气调节阀下限',
                  'V_RS3',
                  'OUTB'
                )
              ">
						{{ infoList.V_RS3.SLMQL_B3 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS3.SLKQL_B3,
                  '3#空气调节阀下限',
                  'OUTB',
                  'V_GONGGONG__p__MAN1'
                )
              ">
						{{ infoList.V_RS3.SLKQL_B3 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS3.BLMQL_B3,
                  '3#煤气调节阀下限(闭炉时)',
                  'BLMQL_B3',
                  'V_RS3'
                )
              ">
						{{ infoList.V_RS3.BLMQL_B3 }}
					</div>
					<div class="last-child" @click="
                toIpt(
                  infoList.V_RS3.BLKQL_B3,
                  '3#空气调节阀下限(闭炉时)',
                  'BLKQL_B3',
                  'V_RS3'
                )
              ">
						{{ infoList.V_RS3.BLKQL_B3 }}
					</div>
				</div>
				<div class="tab-row">
					<div></div>
					<div>4#煤气调节阀上下限</div>
					<div>4#空气调节阀上下限</div>
					<div>4#煤气调节阀上下限(闭炉时）</div>
					<div class="lastchild">4#空气调节阀上下限(闭炉时）</div>
				</div>
				<div class="tab-row1">
					<div></div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB4__p__MAN1.OUTT,
                  '4#煤气调节阀上限',
                  'OUTT',
                  'V_RS__p__RSFB4__p__MAN1'
                )
              ">
						{{ infoList.V_RS__p__RSFB4__p__MAN1.OUTT }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS__p__RSFB4__p__MAN2.OUTT,
                  '3#空气调节阀上限',
                  'OUTT',
                  'V_RS__p__RSFB4__p__MAN2'
                )
              ">
						{{ infoList.V_RS__p__RSFB4__p__MAN2.OUTT }}
					</div>
					<div></div>
					<div class="last-child"></div>
				</div>
				<div class="tab-row1 tab-row2">
					<div></div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS4.SLMQL_B4,
                  '4#煤气调节阀下限',
                  'SLMQL_B4',
                  'V_RS4'
                )
              ">
						{{ infoList.V_RS4.SLMQL_B4 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS4.SLKQL_B4,
                  '4#空气调节阀下限',
                  'SLKQL_B4',
                  'V_RS4'
                )
              ">
						{{ infoList.V_RS4.SLKQL_B4 }}
					</div>
					<div class="widthmin" @click="
                toIpt(
                  infoList.V_RS4.BLMQL_B4,
                  '4#煤气调节阀下限(闭炉时)',
                  'BLMQL_B4',
                  'V_RS4'
                )
              ">
						{{ infoList.V_RS4.BLMQL_B4 }}
					</div>
					<div class="last-child" @click="
                toIpt(
                  infoList.V_RS4.BLKQL_B4,
                  '4#空气调节阀下限(闭炉时)',
                  'BLKQL_B4',
                  'V_RS4'
                )
              ">
						{{ infoList.V_RS4.BLKQL_B4 }}
					</div>
				</div>
			</div>
		</div>
		<div class="security-tab">
			<div class="security-tab-tit security-tab-tit1">关键报警设置参数</div>
			<div class="security-tab-box1 flex">
				<div class="tab-row-s">
					<div class="row-s-div"></div>
					<div class="row-s-div"></div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT1_B1, '1#拱顶温度高限','BJQT1_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT1_B1" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT2_B1, '1#烟气温度高限','BJQT2_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT2_B1" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT1_B2, '2#拱顶温度高限','BJQT1_B2','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT1_B2" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT2_B2, '2#烟气温度高限','BJQT2_B2','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT2_B2" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT3_B1, '1#拱顶信号异常报警','BJQT3_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT3_B1" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div last-child">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT4_B1, '1#废气信号异常报警','BJQT4_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT4_B1" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
				</div>
				<div class="tab-row-b">
					<div>煤气总管压力低限</div>
					<div>空气总管压力低限</div>
					<div>1#拱顶温度高限</div>
					<div>1#烟气温度高限</div>
					<div>2#拱顶温度高限</div>
					<div>2#烟气温度高限</div>
					<div>1#拱顶信号异常报警</div>
					<div class="last-child">1#废气信号异常报警</div>
				</div>
				<div class="tab-row-t">
					<div @click="toIpt(infoList.V_ALARM.MQZGYLL,'煤气总管压力低限','MQZGYLL','V_ALARM')">
						{{ infoList.V_ALARM.MQZGYLL }}
					</div>
					<div @click="toIpt(infoList.V_ALARM.KQZGYLL,'空气总管压力低限','KQZGYLL','V_ALARM')">
						{{ infoList.V_ALARM.KQZGYLL }}
					</div>
					<div @click="toIpt(infoList.V_SOUREC.GDWDH_B1,'1#拱顶温度高限','GDWDH_B1','V_SOUREC')">
						{{ infoList.V_SOUREC.GDWDH_B1 }}
					</div>
					<div @click="toIpt(infoList.V_RS1.FQBHZ_B1,'1#烟气温度高限','FQBHZ_B1','V_RS1')">
						{{ infoList.V_RS1.FQBHZ_B1 }}
					</div>
					<div @click="toIpt(infoList.V_SOUREC.GDWDH_B2,'2#拱顶温度高限','GDWDH_B2','V_SOUREC')">
						{{ infoList.V_SOUREC.GDWDH_B2 }}
					</div>
					<div @click="toIpt(infoList.V_RS2.FQBHZ_B2,'2#烟气温度高限','FQBHZ_B2','V_RS2')">
						{{ infoList.V_RS2.FQBHZ_B2 }}
					</div>
					<div></div>
					<div class="last-child"></div>
				</div>
				<div class="tab-row-s">
					<div class="row-s-div"></div>
					<div class="row-s-div"></div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT1_B3, '3#拱顶温度高限','BJQT1_B3','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT1_B3" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT2_B3, '3#烟气温度高限','BJQT2_B3','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT2_B3" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT1_B4, '4#拱顶温度高限','BJQT1_B4','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT1_B4" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv" @click="toIpt(infoList.V_ALARM.BJQT2_B4, '4#烟气温度高限','BJQT2_B4','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT2_B4" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">

						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT3_B2, '2#拱顶信号异常报警','BJQT3_B2','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT3_B2" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div last-child">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT4_B2, '2#废气信号异常报警','BJQT4_B2','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT4_B2" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
				</div>
				<div class="tab-row-b">
					<div>OPC读通讯监测</div>
					<div>OPC写通讯监测</div>
					<div>3#拱顶温度高限</div>
					<div>3#烟气温度高限</div>
					<div>4#拱顶温度高限</div>
					<div>4#烟气温度高限</div>
					<div>2#拱顶信号异常报警</div>
					<div class="last-child">2#废气信号异常报警</div>
				</div>
				<div class="tab-row-t">
					<div @click="toCompon(2, 'HEARTS_B', 'V_SOUREC', 'HEARTS_B_WF','原系统产生心跳数据')"
						@dblclick="Cclick(infoList.V_SOUREC.HEARTS_B,'HEARTS_B', 'V_SOUREC', 'HEARTS_B_WF')">
						{{ infoList.V_SOUREC.HEARTS_B }}
					</div>
					<div @click="toCompon(2, 'HEARTR_B', 'V_SOUREC', 'HEARTR_B_WF','原系统接收返回心跳数据')"
						@dblclick="Cclick(infoList.V_SOUREC.HEARTR_B,'HEARTR_B', 'V_SOUREC', 'HEARTR_B_WF')">
						{{ infoList.V_SOUREC.HEARTR_B }}
					</div>
					<div @click="toIpt(infoList.V_SOUREC.GDWDH_B3,'3#拱顶温度高限','GDWDH_B3','V_SOUREC')">
						{{ infoList.V_SOUREC.GDWDH_B3 }}
					</div>
					<div @click="toIpt(infoList.V_RS3.FQBHZ_B3,'3#烟气温度高限','FQBHZ_B3','V_RS3')">
						{{ infoList.V_RS3.FQBHZ_B3 }}
					</div>
					<div @click="toIpt(infoList.V_SOUREC.GDWDH_B4,'4#拱顶温度高限','GDWDH_B4','V_SOUREC')">
						{{ infoList.V_SOUREC.GDWDH_B4 }}
					</div>
					<div @click="toIpt(infoList.V_RS4.FQBHZ_B4,'4#烟气温度高限','FQBHZ_B4','V_RS4')">
						{{ infoList.V_RS4.FQBHZ_B4 }}
					</div>
					<div></div>
					<div class="last-child"></div>
				</div>
				<div class="tab-row-s">
					<div class="row-s-div"></div>
					<div class="row-s-div"></div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT6_B1, '1#煤气流量异常报警','BJQT6_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT6_B1" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT7_B1, '1#空气流量异常报警','BJQT7_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT7_B1" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT6_B2, '1#煤气流量异常报警','BJQT6_B2','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT6_B2" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT7_B2, '1#空气流量异常报警','BJQT7_B2','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT7_B2" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT3_B3, '3#拱顶信号异常报警','BJQT3_B3','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT3_B3" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div last-child">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT4_B3, '3#废气信号异常报警','BJQT4_B3','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT4_B3" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
				</div>
				<div class="tab-row-b">
					<div>自控率低报警</div>
					<div>OPC通讯异常</div>
					<div>1#煤气流量异常报警</div>
					<div>1#空气流量异常报警</div>
					<div>1#煤气流量异常报警</div>
					<div>1#空气流量异常报警</div>
					<div>3#拱顶信号异常报警</div>
					<div class="last-child">3#废气信号异常报警</div>
				</div>
				<div class="tab-row-s">
					<div class="row-s-div"></div>
					<div class="row-s-div"></div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT6_B3, '1#煤气流量异常报警','BJQT6_B3','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT6_B3" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT7_B3, '1#空气流量异常报警','BJQT7_B1','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT7_B3" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT6_B4, '1#煤气流量异常报警','BJQT6_B4','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT6_B4" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT7_B4, '1#空气流量异常报警','BJQT7_B4','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT7_B4" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT3_B4, '4#拱顶信号异常报警','BJQT3_B4','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT3_B4" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
					<div class="row-s-div last-child">
						<div class="boxdiv"
							@click="toIpt(infoList.V_ALARM.BJQT4_B4, '4#废气信号异常报警','BJQT4_B4','V_ALARM')">
							<el-switch v-model="infoList.V_ALARM.BJQT4_B4" active-color="#29DFAE" inactive-color="red"
								disabled>
							</el-switch>
						</div>
					</div>
				</div>
				<div class="tab-row-b">
					<div>控制参数初始化报警</div>
					<div>烧炉时间到报警</div>
					<div>1#煤气流量异常报警</div>
					<div>1#空气流量异常报警</div>
					<div>1#煤气流量异常报警</div>
					<div>1#空气流量异常报警</div>
					<div>4#拱顶信号异常报警</div>
					<div class="last-child">4#废气信号异常报警</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	export default {
		name: "Security",
		components: {
			Historical,
			inputVal
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		data: () => {
			return {
				chName: '',
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				isComShow: false,
				isHshow: false,
				isClose: false,
				projectData: {},
				spotArr: [],
				grouptime: null
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			DataJson(data) {
				console.log(data)
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			//    打开输入框组件。
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			isClose1() {
				this.isHshow = false;
			},
			toCompon(key, name, name2, name3, name4) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (this.isMshow = true);
						case 1:
							return (this.isFshow = true);
						case 2:
							return (this.isHshow = true, this.chName = name4);
					}
				}, 300)

			},
		},
	};
</script>
<style lang="scss" scoped>
	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 26vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 3vh;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.evaluate-header {
		height: 8vh;
		// background: #5DCEF3;
		line-height: 8vh;
		//  background: #58cdf4;
		//  line-height: 50px;
		text-align: center;
		color: #0ef5fd;
		font-size: 3vh;
		position: relative;
		border-radius: 5px 5px 0 0;
	}

	.security-tab {
		margin-top: 3vh;

		.security-tab-tit {
			width: 100%;
			height: 4vh;
			line-height: 4vh;
			font-size: 2.8vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			text-align: center;
			letter-spacing: 1vh;
			color: #ffffff;
			margin-bottom: 1vh;
		}

		.security-tab-tit1 {
			margin-top: 2vh;
		}

		.security-tab-box {
			width: 95vw;
			margin: 0 auto;
			height: 24vh;
			border: 1px solid rgba(0, 228, 255, 0.2);
			box-sizing: border-box;

			.tab-row {
				width: 15vw;
				text-align: center;
				font-size: 1vw;
				color: #6acafb;
				border-right: 1px solid rgba(0, 228, 255, 0.2);
				box-sizing: border-box;

				div {
					height: 4.8vh;
					line-height: 4.8vh;
					border-bottom: 1px solid rgba(0, 228, 255, 0.2);
					box-sizing: border-box;
				}

				div:last-child {
					border-bottom: none;
				}
			}

			.tab-row1 {
				width: 4.375vw;
				text-align: center;
				border-right: 1px solid rgba(0, 228, 255, 0.2);
				box-sizing: border-box;
				color: #fff;
				font-size: 1vw;

				div {
					height: 4.8vh;
					line-height: 4.8vh;
					border-bottom: 1px solid rgba(0, 228, 255, 0.2);
					box-sizing: border-box;
					cursor: pointer;
				}

				.last-child {
					border-bottom: none;
				}
			}

			.tab-row2 {
				border-right: none;
			}
		}

		.security-tab-box1 {
			width: 95vw;
			margin: 0 auto;
			height: 38vh;
			border: 1px solid rgba(0, 228, 255, 0.2);
			box-sizing: border-box;

			.tab-row-s {
				width: 4.5vw;
				text-align: center;
				border-right: 1px solid rgba(0, 228, 255, 0.2);
				box-sizing: border-box;

				.row-s-div {
					height: 4.75vh;
					line-height: 4.75vh;
					border-bottom: 1px solid rgba(0, 228, 255, 0.2);
					box-sizing: border-box;

					.boxdiv {
						width: 100%;
						height: 100%;
						position: relative;
						cursor: pointer;
					}

					.el-switch {
						position: relative;
						z-index: 1 !important;
					}

					.el-switch.is-disabled {
						opacity: 1 !important;
					}
				}

				.last-child {
					border-bottom: none;
				}

			}

			.tab-row-b {
				width: 17vw;
				text-align: center;
				border-right: 1px solid rgba(0, 228, 255, 0.2);
				box-sizing: border-box;

				div {
					height: 4.75vh;
					line-height: 4.75vh;
					border-bottom: 1px solid rgba(0, 228, 255, 0.2);
					box-sizing: border-box;
					color: #6acafb;
					font-size: 1vw;
				}

				.last-child {
					border-bottom: none;
				}
			}

			.tab-row-t {
				width: 4.5vw;
				text-align: center;
				border-right: 1px solid rgba(0, 228, 255, 0.2);
				box-sizing: border-box;

				div {
					height: 4.75vh;
					line-height: 4.75vh;
					border-bottom: 1px solid rgba(0, 228, 255, 0.2);
					box-sizing: border-box;
					color: #fff;
					font-size: 1vw;
					cursor: pointer;
				}

				.last-child {
					border-bottom: none;
				}
			}
		}
	}

	::v-deep {
		.el-switch.is-disabled .el-switch__core {
			cursor: pointer !important;
		}
	}
</style>
